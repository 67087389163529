import React, { useEffect, useState, Fragment } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import { connect } from "react-redux";
import { uplaodPaper } from "../../actions/research";

const UplaodPaper = ({
  uplaodPaper,
  research: { _id, researchName, theHub, author, email, status },
}) => {
  const [Paper, setPaper] = useState("");
  const [mss, setmss] = useState("");
  const [filename, setFilename] = useState(
    " تحميل الورقة كاملة / Upload the Paper "
  );

  const onChangefile = (e) => {
    if (
      e.target.files[0].type === "application/pdf" ||
      e.target.files[0].type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      e.target.files[0].type === "application/msword"
    ) {
      setPaper(e.target.files[0]);
      setFilename(e.target.files[0].filename);
    } else {
      M.toast(
        {
          html: "الملف غير مدعوم ارجو التأكد من ان يكون الملف بصيغة pdf او doc",
        },
        { outDuration: 500 }
      );
      setmss("الملف غير مدعوم ارجو التأكد من ان يكون الملف بصيغة pdf او doc");
    }
  };

  const onSubmit = () => {
    if (_id === "") {
      M.toast(
        {
          html: "لم المراجع غير موجود  ",
        },
        { outDuration: 500 }
      );
    } else {
      uplaodPaper(Paper, _id);
      M.toast(
        {
          html: "تمت الاضافة بنجاح",
        },
        { outDuration: 500 }
      );
    }
  };
  return (
    <div id={`paper`} className="modal">
      <div className="modal-content">
        <div className="row">
          <div className="input-field">
            <input type="text" name="author" value={author} disabled />
          </div>
        </div>
        <div className="row">
          <div className="input-field">
            <input
              type="text"
              name="researchName"
              value={researchName}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="input-field">
            <input type="text" name="theHub" value={theHub} disabled />
          </div>
        </div>
        <div className="row">
          <div className="input-field">
            <input type="text" name="email" value={email} required />
          </div>
        </div>
        <div className="file-field input-field">
          <div className="btn">
            <span>File</span>
            <input type="file" onChange={onChangefile} />
          </div>
          <div className="file-path-wrapper">
            <input
              className="file-path validate"
              type="text"
              // eslint-disable-next-line
              value={filename}
              required
            />
          </div>
        </div>
        <label htmlFor="firstName" className="active">
          يمكن تحميل ملف في ( doc) هدا الحقل
        </label>
        <span className="red-text">{mss}</span>
      </div>

      <div className="modal-footer">
        <a
          href="#!"
          onClick={onSubmit}
          className="modal-close waves-effect blue waves-light btn"
        >
          ارسال
        </a>
      </div>
    </div>
  );
};

export default connect(null, { uplaodPaper })(UplaodPaper);
