import React, { Fragment, useEffect } from "react";
import "./homepage.style.css";
import Timer from "../../components/timer/timer";
import M from "materialize-css/dist/js/materialize.min.js";
import RegisterationModal from "../../components/models/RegisterationModal";
import { connect } from "react-redux";
import ContactFilter from "../../components/models/SearchMadol";
import { ChangeLang } from "../../actions/research";
import {
  head1,
  head2,
  head3,
  head4,
  head41,
  head5,
  head6,
  about1,
  about2,
  about3,
  about4,
  about5,
  about6,
  Gools1,
  Gools2,
  m1,
  m2,
  m3,
  m4,
  m5,
  m6,
  m7,
  m8,
  m9,
  m10,
  m11,
  m12,
  m13,
  s,
  comission,
  c1,
  c2,
  D1,
  D2,
  Node,
  TNode,
  footer,
  Close,
} from "./sections";
import UploadPaperModal from "../../components/models/uploadPaperModal";

const HomePage = ({ lang, ChangeLang }) => {
  useEffect(() => {
    var elems = document.querySelectorAll(".collapsible");
    M.Collapsible.init(elems, {});
    var modal = document.querySelectorAll(".modal");
    M.Modal.init(modal, {});

    var tooltips = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(tooltips, {});
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <section className="showcase center">
        <div className="video-container">
          <video src="/img/1.mp4" autoPlay muted loop />
        </div>
        <div className="content ">
          <div>
            <a
              href="#!"
              className={
                lang === "en" ? "btn right white-text" : "btn left white-text"
              }
              onClick={() => ChangeLang("ar")}
            >
              عربي
            </a>
            <a
              href="#!"
              className={
                lang === "en" ? "btn right white-text" : "btn left white-text"
              }
              onClick={() => ChangeLang("en")}
            >
              English
            </a>
          </div>
          <span>
            <img
              src="/img/logo.png"
              alt="logo"
              width="150"
              style={{ opacity: "0.7" }}
            />
          </span>
          <h4>{head1[lang]}</h4>
          <h3 dangerouslySetInnerHTML={{ __html: head2[lang] }}></h3>
          <h3>
            <h4>{head5[lang]} </h4>
            <Timer lang={lang} />
          </h3>
          <br />
          <h4>
            {" "}
            {head3[lang]}
            {"   "}
            <span> 124</span>
            {"   "}
            {head4[lang]} {"   "} 102
            {"   "}
            {head41[lang]} {"   "} 53
          </h4>
          {Date.now() < new Date("2020-06-12") ? (
            <Fragment>
              <a
                href="#register"
                className="modal-trigger  hide-on-small-only btn-large red darken-2 waves-effect waves-light btn "
              >
                <i class="material-icons left">border_color</i>
                {lang === "ar"
                  ? "التسجيل في المؤتمر"
                  : "Register for the conference"}
              </a>
              <a
                href="#video"
                className="modal-trigger  hide-on-small-only btn-large red darken-2 waves-effect waves-light btn "
              >
                <i class="material-icons left">play_circle_filled</i>
                {lang === "ar" ? "كيفية التسجيل" : "How to Register"}
              </a>
            </Fragment>
          ) : (
            <a
              href="#list"
              className="modal-trigger   btn-large red darken-2 waves-effect waves-light btn "
            >
              {" "}
              <i class="material-icons left">format_list_numbered</i>
              {lang === "ar"
                ? " قائمة الملخصات المقبولة"
                : " accepted Abstructs list "}
            </a>
          )}
        </div>
      </section>

      <section
        id="about"
        className="container "
        style={{ direction: lang === "en" ? "ltr" : "rtl" }}
      >
        <h3 className=" teal-text darken-4" style={{ marginTop: "50px" }}>
          {head6[lang]}
        </h3>
        <p style={{ fontSize: "1.3rem", lineHeight: "2.4rem" }}>
          {about1[lang]}
        </p>
        <h5 style={{ lineHeight: "3rem" }}>
          {about2[lang]}
          <a class="waves-effect  modal-trigger teal-text" href="#modal1">
            {about3[lang]}
          </a>
        </h5>

        <div id="modal1" className="modal" style={{ width: "80%" }}>
          <div class="modal-content">
            <h4>{about4[lang]}</h4>
            <ol dangerouslySetInnerHTML={{ __html: about5[lang] }}></ol>
            <p className="center-align">{about6[lang]}</p>
          </div>
          <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat">
              {Close[lang]}
            </a>
          </div>
        </div>

        <h3 className="teal-text darken-2" style={{ marginTop: "50px" }}>
          {Gools1[lang]}
        </h3>
        <ol dangerouslySetInnerHTML={{ __html: Gools2[lang] }}></ol>
        <h3 className="teal-text darken-2" style={{ marginTop: "50px" }}>
          {m1[lang]}
        </h3>
        <ul class="collapsible">
          <li>
            <div class="collapsible-header">
              <h6>
                <i
                  class="material-icons teal-text"
                  style={{ marginLeft: "15px" }}
                >
                  check
                </i>
                {m2[lang]}
              </h6>
            </div>
            <div
              class="collapsible-body"
              dangerouslySetInnerHTML={{ __html: m3[lang] }}
            >
              {/* html */}
            </div>
          </li>
          <li>
            <div class="collapsible-header">
              <h6>
                <i
                  class="material-icons teal-text"
                  style={{ marginLeft: "15px" }}
                >
                  check
                </i>
                {m4[lang]}
              </h6>
            </div>
            <div
              class="collapsible-body"
              dangerouslySetInnerHTML={{ __html: m5[lang] }}
            >
              {/* html */}
            </div>
          </li>
          <li>
            <div class="collapsible-header">
              <h6>
                <i
                  class="material-icons teal-text"
                  style={{ marginLeft: "15px" }}
                >
                  check
                </i>
                {m6[lang]}
              </h6>
            </div>
            <div
              class="collapsible-body"
              dangerouslySetInnerHTML={{ __html: m7[lang] }}
            >
              {/* html */}
            </div>
          </li>
          <li>
            <div class="collapsible-header">
              <h6>
                <i
                  class="material-icons teal-text"
                  style={{ marginLeft: "15px" }}
                >
                  check
                </i>
                {m8[lang]}{" "}
              </h6>
            </div>
            <div
              class="collapsible-body"
              dangerouslySetInnerHTML={{ __html: m9[lang] }}
            >
              {/* html */}
            </div>
          </li>
          <li>
            <div class="collapsible-header">
              <h6>
                <i
                  class="material-icons teal-text"
                  style={{ marginLeft: "15px" }}
                >
                  check
                </i>
                {m10[lang]}{" "}
              </h6>
            </div>
            <div
              class="collapsible-body"
              dangerouslySetInnerHTML={{ __html: m11[lang] }}
            >
              {/* html */}
            </div>
          </li>
          <li>
            <div class="collapsible-header">
              <h6>
                <i
                  class="material-icons teal-text"
                  style={{ marginLeft: "15px" }}
                >
                  check
                </i>
                {m12[lang]}{" "}
              </h6>
            </div>
            <div
              class="collapsible-body"
              dangerouslySetInnerHTML={{ __html: m13[lang] }}
            >
              {/* html */}
            </div>
          </li>
        </ul>
        <br />
      </section>
      {/* <!-- Section: Search --> */}

      {Date.now() < new Date("2020-07-09") && (
        <section
          id="search"
          class="section section-search teal darken-1 white-text center scrollspy"
        >
          <div class="container">
            <ContactFilter lang={lang} />
          </div>
        </section>
      )}

      {/* Sponsor Section */}
      <section id="supported" className="  teal lighten-5">
        <h3 className=" center-align">{s[lang]}</h3>
        <br />
        <div className="row ">
          <div className="col m3 right">
            <img src="/img/112.png" width="250" alt="Committee" />
          </div>
          <div className="col m3 right">
            {" "}
            <img src="/img/111.png" width="200" alt="Committee" />
          </div>
          <div className="col m4"></div>
        </div>
      </section>
      {/* Regestration Botton */}
      {Date.now() < new Date("2020-06-12") && (
        <div className="fixed-action-btn">
          <a
            href="#register"
            className="modal-trigger btn-floating btn-large  red darken-2 pulse tooltipped"
            data-position="left"
            data-tooltip="لتسجيل في المؤتمر اضغط هنا"
          >
            <i className="material-icons">note_add</i>
          </a>
          <RegisterationModal lang={lang} />
        </div>
      )}
      <section id="commission">
        <h3 className=" teal-text center-align container">
          {" "}
          {comission[lang]}
        </h3>
        <div className="row">
          <div class="row">
            <div className="col m3  item4">
              <div class="card ">
                <div class="card-image">
                  <img src="img/14.jpg" height="300" alt="Committee" />
                </div>
                <div class="card-content center-align">
                  {lang === "ar" ? (
                    <Fragment>
                      <h5>أ.جمزة كشلاف</h5>
                      <h4>رئيس اللجنة التحضيرية</h4>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <h6>Mr. Hamza Kishlaf </h6>
                      <h6>Chairman of the Preparatory </h6>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className="col m3  item3">
              <div class="card ">
                <div class="card-image">
                  <img src="img/13.jpg" height="300" alt="Committee" />
                </div>
                <div class="card-content center-align">
                  {lang === "ar" ? (
                    <Fragment>
                      <h5>د. الصديق خليفة الكيلاني</h5>
                      <h4>رئيس المؤتمر</h4>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <h6>Dr. Esediek Khalifa Alkelani </h6>
                      <h6>Conference Chairman </h6>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className="col m3  item2">
              <div class="card ">
                <div class="card-image">
                  <img src="img/112.jpg" height="300" alt="Committee" />
                </div>
                <div class="card-content center-align">
                  {lang === "ar" ? (
                    <Fragment>
                      <h5>ا.د عبدالحكيم بشير الزويك</h5>
                      <h5>وكيل الجامعة للشؤون العلمية</h5>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <h6>Dr. Abdelhakeem Basher Azwiek </h6>
                      <h6>
                        The undersecretary for scientific affairs at the
                        university{" "}
                      </h6>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className="col m3 item1">
              <div class="card ">
                <div class="card-image">
                  <img src="img/11.jpg" height="300" alt="Committee" />
                </div>
                <div class="card-content center-align">
                  {lang === "ar" ? (
                    <Fragment>
                      <h5>د. مفتاح المرابط</h5>
                      <h4>الرئيس الفخري للمؤتمر </h4>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <h6>Dr. Moftah almarabat </h6>
                      <h6>Honorary President of the Conference </h6>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col m3">
            <div class="card">
              <div class="card-image waves-effect waves-block waves-light">
                <img
                  class="activator"
                  src="img/4.jpg"
                  height="300"
                  alt="Committee"
                />
              </div>
              <div class="card-content">
                <span
                  class="card-title activator grey-text text-darken-4"
                  style={{ fontSize: lang === "en" ? "1.2rem" : "1.8rem" }}
                >
                  {" "}
                  {lang === "ar"
                    ? "رئيس لجنة الخدمات"
                    : "Chairman of the Services Committee "}
                  <i class="material-icons right">more_vert</i>
                </span>
                <p className=" center-align ">
                  <a class="waves-effect teal-text modal-trigger" href="#4">
                    {" "}
                    {lang === "ar"
                      ? " اعضاء لجنة الخدمات"
                      : "Committee for the conference"}
                  </a>
                </p>
              </div>
              <div class="card-reveal">
                <span class="card-title grey-text text-darken-4">
                  <i class="material-icons right">close</i>
                </span>
                <br />
                <h5>أ. محمد احمد عجينة </h5>
                <h6> الصفة : الكاتب العام بالجامعة</h6>
              </div>
            </div>
          </div>
          <div className="col m3">
            <div class="card">
              <div class="card-image waves-effect waves-block waves-light">
                <img
                  class="activator"
                  src="img/3.jpg"
                  height="300"
                  alt="Committee"
                />
              </div>
              <div class="card-content">
                <span
                  class="card-title activator grey-text text-darken-4"
                  style={{ fontSize: lang === "en" ? "1.2rem" : "1.8rem" }}
                >
                  {lang === "ar"
                    ? "رئيس اللجنة التقنية"
                    : "Head of the Technical Committee "}{" "}
                  <i class="material-icons right">more_vert</i>
                </span>
                <p className=" center-align ">
                  <a class="waves-effect teal-text modal-trigger" href="#3">
                    {" "}
                    {lang === "ar"
                      ? "اعضاء اللجنة التقنية"
                      : "Members of the Technical Committee "}{" "}
                  </a>
                </p>
              </div>
              <div class="card-reveal">
                <span class="card-title grey-text text-darken-4">
                  <i class="material-icons right">close</i>
                </span>
                <br />
                <h5>د. محمد إمحمد البشتي </h5>
                <h6> الصفة : رئيس مركز التعليم الالكتروني</h6>
              </div>
            </div>
          </div>
          <div className="col m3">
            <div class="card">
              <div class="card-image waves-effect waves-block waves-light">
                <img
                  class="activator"
                  src="img/22.jpg"
                  height="300"
                  alt="Committee"
                />
              </div>
              <div class="card-content">
                <span
                  class="card-title activator grey-text text-darken-4"
                  style={{ fontSize: lang === "en" ? "1.2rem" : "1.8rem" }}
                >
                  {lang === "ar"
                    ? "رئيس اللجنة العلمية"
                    : "Chair of the Scientific Committee "}{" "}
                  <i class="material-icons right">more_vert</i>
                </span>
                <p className=" center-align ">
                  <a class="waves-effect teal-text modal-trigger" href="#2">
                    {" "}
                    {lang === "ar"
                      ? "اعضاء اللجنة العلمية"
                      : "Members of the Scientific Committee "}{" "}
                  </a>
                </p>
              </div>
              <div class="card-reveal">
                <span class="card-title grey-text text-darken-4">
                  <i class="material-icons right">close</i>
                </span>
                <br />
                <h5>د. عماد محمد ابوعجيلة </h5>
                <h6> الصفة : رئيس مركز البحوث و الاستشارات</h6>
              </div>
            </div>
          </div>
          <div className="col m3">
            <div class="card">
              <div class="card-image waves-effect waves-block waves-light">
                <img
                  class="activator"
                  src="img/12.jpg"
                  height="300"
                  width="200"
                  alt="Committee"
                />
              </div>
              <div class="card-content">
                <span
                  class="card-title activator grey-text text-darken-4 center"
                  style={{ fontSize: lang === "en" ? "1.2rem" : "1.8rem" }}
                >
                  {lang === "ar"
                    ? "نائب رئيس اللجنة التحضيرية"
                    : "Vice-President of the Preparatory Committee"}{" "}
                  <i class="material-icons right">more_vert</i>
                </span>
                <p className=" center-align ">
                  <a class="waves-effect teal-text modal-trigger" href="#1">
                    {lang === "ar"
                      ? " اعضاء اللجنة التحضيرية  "
                      : "Members of the Preparatory Committee"}{" "}
                  </a>
                </p>
              </div>
              <div class="card-reveal">
                <span class="card-title grey-text text-darken-4">
                  <i class="material-icons right">close</i>
                </span>
                <br />
                <h5>د. حسن علي موتات</h5>
                <h6>الصفة : عضؤ هيئة تدريس بكلية الاقتصاد</h6>
              </div>
            </div>
          </div>
        </div>

        {/* commission Modals */}

        {/* Madul 1 */}
        <div id="1" class="modal">
          <div class="modal-content">
            <h4>أعضاء اللجنة التحضيرية </h4>
            <ol>
              <li>د. حسين علي القمباوي {"      "} (عضوا)</li>
              <li> د. عبدالله رمضان بنيني {"      "} (عضوا)</li>
              <li> د. فوزية محمد مندرة {"      "} (عضوا)</li>
              <li> د. محمود أحمد فحيل بوم {"      "} (عضوا)</li>
              <li> د. شوقي إبراهيم صالح معمر {"      "} (عضوا)</li>
              <li> أ. ياسين عبدالحميد أبوسرويل {"      "} (عضوا)</li>
              <li> أ. عبدالمنعم المنتصر القمي {"      "} (عضوا)</li>
              <li>أ. هيثم احمد الهنقاري {"      "} (عضوا)</li>
              <li>أ . سمية بلعيد الشوشان {"      "} (عضوا)</li>
            </ol>
          </div>
          <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat">
              {Close[lang]}
            </a>
          </div>
        </div>
        {/* modal 2 */}
        <div id="2" class="modal">
          <div class="modal-content">
            <h4> اعضاء اللجنة العلمية</h4>
            <ol>
              <li>أ.د. سالم الشريف الفرد {"      "} (عضوا)</li>
              <li>أ.د. عبدالمجيد الطيب شعبان {"      "} (عضوا)</li>
              <li>د. محمد الطيب القمودي {"      "} (عضوا)</li>
              <li>د. سليمان عمر منصور {"      "} (عضوا)</li>
              <li>د. أحمد سليمان عمر {"      "} (عضوا)</li>
              <li>د. يوسف شعبان المبروك {"      "} (عضوا)</li>
              <li>د. عبداللطيف البشير التونسي {"      "} (عضوا)</li>
              <li>د. محمد إمحمد البشتي {"      "} (عضوا)</li>
              <li>
                {" "}
                د. محمد الشيباني محمد أبوشعفة. {"      "} (عضوا) – بريطانيا
              </li>
              <li> أ. د. جودث ماكيون {"      "} (عضوا) – بريطانيا</li>

              <li> أ. د. سلاف سماوي {"      "} (عضوا) – تونس</li>
              <li> أ. د. بورقبة قويدر بن ميلود {"      "} (عضوا) – الجزائر</li>
              <li> أ.د. شهاب الدين رجائي محمود {"      "} (عضوا) – مصر</li>
              <li> أ.د. منير فرج المبروك الكبير {"      "} (عضوا) – ماليزيا</li>
              <li>
                {" "}
                أ. د. عبدالمولى عبدالمجيد الهادي {"      "} (عضوا) – ماليزيا
              </li>
            </ol>
          </div>
          <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat">
              {Close[lang]}
            </a>
          </div>
        </div>
        {/* madol 3 */}

        <div id="3" class="modal">
          <div class="modal-content">
            <h4>اعضاء اللجنة التقنية </h4>
            <ol>
              <li> د. حسن مولود الشيباني {"      "} (عضوا)</li>
              <li> د.محمد الشيباني ابوشعفة {"      "} (عضوا)</li>
              <li> أ. أحمد إبراهيم سليمان {"      "} (عضوا)</li>
              <li> أ. مروة محمد الزروق {"      "} (عضوا)</li>
              <li> أ. نسرين مفتاح الواعر {"      "} (عضوا)</li>
              <li> أ. حامد حسين بن كورة {"      "} (عضوا)</li>
              <li> أ. محمود جمعة البشتي {"      "} (عضوا)</li>
              <li> أ.أميرة محمد جمعة {"      "} (عضوا)</li>
            </ol>
          </div>
          <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat">
              {Close[lang]}
            </a>
          </div>
        </div>
        {/* modal 4 */}
        <div id="4" class="modal">
          <div class="modal-content">
            <h4>اعضاء لجنة الخدمات </h4>
            <ol>
              <li> السيد: الشيباني محمد نصرات {"      "} (عضوا)</li>
              <li> السيد: محمد سالم المبروك{"      "} (عضوا)</li>
              <li> السيدة: اسماء الحبيب الشاوش {"      "}(عضوا)</li>
              <li> السيد: علي محمد كريم {"      "} (عضوا)</li>
              <li> السيد: محمود سالم حشون {"      "} (عضوا)</li>
              <li>
                السيدة: زينب محمد ابريبش
                {"      "}(عضوا)
              </li>
            </ol>
          </div>
          <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat">
              {Close[lang]}
            </a>
          </div>
        </div>
      </section>

      <section
        id="coordinator"
        className="teal lighten-5"
        style={{ direction: lang === "en" ? "ltr" : "rtl" }}
      >
        <div className="row">
          <div className="col m6 ">
            <h3 className=" center-align"> {D1[lang]}</h3>
            <ul
              className="date"
              dangerouslySetInnerHTML={{ __html: D2[lang] }}
            ></ul>
          </div>
          <div className="col m6 d2">
            <h3 className=" center-align"> {c1[lang]}</h3>
            <div dangerouslySetInnerHTML={{ __html: c2[lang] }}></div>
          </div>
        </div>
      </section>
      {/* Video Madol */}
      <div id="video" class="modal">
        <div class="modal-content">
          <h4>
            {lang === "ar"
              ? "فيديو توضيحي لعملية التسجيل في المؤتمر"
              : "Video How to Register"}
          </h4>
          <div className=" video-madol">
            <video src="/img/how.mp4" controls />
          </div>
        </div>
        <div class="modal-footer">
          <a href="#!" class="modal-close waves-effect waves-green btn-flat">
            {Close[lang]}
          </a>
        </div>
      </div>
      {/* List Madol */}
      <div id="list" class="modal">
        <div class="modal-content">
          <h4>
            {lang === "ar"
              ? "  قائمة الملخصات المقبولة"
              : "Statistics and accepted Abstructs list "}
          </h4>
          <div>
            <ol>
              <ol>
                <li>
                  انعكاسات التكنولوجيا الرقمية على تنمية الموارد البشرية - حازم
                  أحمد فروانة - جامعة ابي بكر بلقايد تلمسان - الجزائر
                </li>
                <li>
                  دور التعليم الإلكتروني في تطوير مهارات معلمي المستقبل (دراسة
                  مطبقة على طلاب كلية التربية بجامعة الامام عبد الرحمن بن فيصل
                  بالسعودية) - أ‌. م. د / دعاء عبد الحميد عبد السميع محمد -
                  جامعة الامام عبد الرحمن بن فيصل -كلية الاداب - المملكة العربية
                  السعودية
                </li>
                <li>
                  التعليم الإلكتروني في ظل انتشار جائحة كورونا – المنجزات
                  والتحديات – دراسة وصفية تحليلية لمنصة التعليم عن بعد قسم اللغة
                  العربية وآدابها جامعة بومرداس - دة.هدى عماري - كلية الآداب
                  واللغات جامعة أمحمد بوقرة بومرداس - الجزائر
                </li>
                <li>
                  أثر جودة الخدمة لموقع الجامعة الالكتروني على رضا الطلبة في ظل
                  التوجه نحو الحوكمة الالكترونية - دراسة تطبيقية على طلبة جامعة
                  سطيف1 - شرشافة إلياس - جامعة سطيف1 - الجزائر
                </li>
                <li>
                  comparison of virtual environment system(balckboard) and open
                  source system (moodle) - نجاة علي محمد اطبيقة - جامعة سرت -
                  ليبيا
                </li>
                <li>
                  تقييم بيئة التعليم الإلكتروني في جامعة مصراتة - عبدالباسط سالم
                  محمد الباعور - جامعة مصراته - Libya
                </li>
                <li>
                  Implementing the Use of Computers in Testing: Implementation,
                  Attitudes, and Effectiveness - Akram Shebani Ahmad Klella أكرم
                  الشيباني أحمد كليلة - Zawia University - Libya
                </li>
                <li>
                  (إنعكاسات التحول الرقمي للعلامات البيئية على التنمية
                  الاقتصادية) - أ.م.د/نشأت ادوارد ناشد - معهد العبور العالي
                  للإدارة والحاسبات ونظم المعلومات - مصر - مصر
                </li>
                <li>
                  " دراسة المشاكل التي تواجه أعضاء هيئة التدريس عند التعامل مع
                  البوابة الالكترونية لجامعة الزاويـــة " - محمود محمد الهادي
                  العكرمي - جامعة الزاوية - كلية التربية ابي عيسى - ليبيا
                </li>
                <li>
                  تطبيقات التعليم الالكتروني بجامعة الزاوية " دراسة تحليلية
                  لبناء وحدات التعليم الإلكتروني بكليات الجامعة" - عمرو سالم
                  دحنس - كلية تقنية المعلومات / جامعة الزاوية - ليبيا
                </li>
                <li>
                  أثر التعلم بالحاسوب في تطوير المهارات الحسابية و تنمية الاتجاه
                  نحو مادة الرياضيات لدى تلاميذ السنة أولى متوسط من ذوي الإعاقة
                  الذهنية البسيطة القابلين للتعلم. - مرصالي حوريه - جامعة خميس
                  مليانة - الجزائر
                </li>
                <li>
                  واقع التحول الرقمي في الإدارة الالكترونية في ظلّ وباء كورونا
                  المستجد (كوفيد-19). - عبد الله ملوكي - جامعة سطيف2، الجزائر -
                  الجزائر
                </li>
                <li>
                  أثر التعليم الإلكتروني في تعزيز جودة التعليم الجامعي من وجهة
                  نظر اعضاء هيئة التدريس بجامعة الزاوية . - ياسين عبدالحميد
                  ابوسريويل - جامعة الزاوية - ليبيا
                </li>
                <li>
                  التدريب الالكتروني كمدخل لتطوير العملية التعليمية في مؤسسات
                  التعليم العالي - د. صلاح عبدالسلام ضو - - جامعة الزنتان- كلية
                  المحاسبة - ليبيا
                </li>
                <li>
                  التعليم الإلكتروني بين استراتيجية التطبيق ومعيقات التحقيق -
                  العربي الحضراوي - كلية الآداب والعلوم الإنسانية جامعة محمد
                  الخامس الرباط - المغرب
                </li>
                <li>
                  أسلوب التعلم الإلكتروني في التعليم العالي: التحديات وإمكانيات
                  التطبيق دراسة ميدانية عن كليات الاقتصاد والإدارة في ليبيا
                  Implementing E-learning in Higher Education: Challenges and
                  Possibilities Evidence from a Field Study on Business Schools
                  in Libya - ّIzzudin Busnaina عزالدين بوسنينه - University of
                  Benghazi جامعة بنغازي - Libya ليبيا
                </li>
                <li>
                  الوجهات الذكية واعتماد التكنولوجيا في الصناعة السياحية تجارب
                  عربية ناجحة - محمد الأمين بودخيل - جامعة بشار - الجزائر
                </li>
                <li>
                  دور تطوير ثقافة المنظمة في دعم التحول الرقمي - مصطفى محمد
                  كليبان الزهيري - محاضر في كلية الامام الاعظم الجامعة - العراق
                </li>
                <li>
                  التعليم الإلكتروني وتطوير بيئة التعليم الجامعي "المركز الوطني
                  للتعلم الالكتروني والتعليم عن بعد" رؤية مقترحة E-learning and
                  the development of the university education environment
                  "National Center for E-Learning and Distance Learning"
                  Suggested vision - أريج إبراهيم عبد الحميد الحاسي - جامعة
                  بنغازي - 0218
                </li>
                <li>
                  نظام الادارة الالكترونية ودورها في تحقيق الكفاءة الادارية -
                  محمد سالم محمد القمبري - الهيئة العامة للمعلومات - Libya
                </li>
                <li>
                  A Proposed Conceptual Framework for Organizational Readiness
                  of Telecommunication firms in Yemen for Digital Business
                  Transformation Strategies - a Conceptual Paper - Nabil
                  Alkhamery - University Sultan Zainal Abidin | UniSZA -
                  Malaysia
                </li>
                <li>
                  اثر البنية التحتية لتكنولوجيا المعلومات والاتصالات على تطور
                  قطاع السياحة:دراسة قياسية على الدول السياحية الافريقية -
                  أبوبكر الفيتوري حدود - جامعة الزاوية ,كلية الاقتصاد, قسم
                  التمويل والمصارف - ليبيا
                </li>
                <li>
                  التعلم الرقمي و إشكالية بناء القيم - حميد حشادي - جامعة ابن
                  طفيل - المغرب
                </li>
                <li>
                  The role of e-learning in the Algerian open virtual university
                  to achieve the development of human capital - Bessaid Oussama
                  Nabil - جامعة ابو بكر بلقايد - تلمسان - الجزائر - الجزائر
                </li>
                <li>
                  وسائل الدفع الالكتروني في ليبيا بين الواقع والطموحات - رواد
                  مسعود سالم سلمان - جامعة الزاوية / كلية العلوم بالزاوية -
                  ليبيا
                </li>
                <li>
                  دوافع التحول نحو الادارة الالكترونية في منظمات الاعمال Motives
                  for the shift towards electronic management in business
                  organizations - قويدر بورقبة - جامعة زيان عاشور-الجلفة-
                  الجزائر مخبر سياسات التنمية الريفية في المناطق السهبية
                  بالجزائر - الجزائر
                </li>
                <li>
                  متطلبات تطبيق الادارة الالكترونية في للمؤسسات العمومية
                  الجزائرية دراسة حالة مكتب الصفقات و المناقصات بالمركز
                  الاستشفائي الجامعي لولاية سيدي بلعباس - قنديل فاطمة الزهراء -
                  جامعة وهران 02 - الجزائر
                </li>
                <li>
                  دور التكنولوجيا الرقمية المطبقة في التربية في رفع تحديات مجتمع
                  المعرفة دراسة حالة المدارس الثانوية في مدينة ورقلة - حازم أحمد
                  فروانة - جامعة ابي بكر بلقايد تلمسان - الجزائر
                </li>
                <li>
                  معوقات تطبيق الإدارة الالكترونية في المؤسسات الحكومية الليبية
                  - طه محمد والي - كلية الاقتصاد والتجارة- الجامعة الاسمرية
                  الاسلامية - ليبيا
                </li>
                <li>
                  حوكمة نظم المعلومات جهاز دائم وحتمي في مواجهة التحول الرقمي
                  للشركة/Governance of information systems permanent feature and
                  an essential face to the digital transformation of the company
                  - Gouadjlia Haithem/قواجلية هيثم - High School of
                  Commerce/المدرسة العليا للتجارة - Algeria/الجزائر
                </li>
                <li>
                  نظم المعلومات الإدارية وعلاقتها بإدارة الأزمات - أحمد محمد
                  البركي - كلية الاقتصاد/ جامعة بنغازي - ليبيا
                </li>
                <li>
                  التعليم الإلكتروني في زمن كورونا - د. حقي حمدي خلف العزاوي -
                  المديرية العامة لتربية ديالي - العراق
                </li>
                <li>
                  دور الإدارة الالكترونية في زيادة كفاءة المؤسسات العمومية
                  الجزائرية . دراسة حالة المصلحة البيومترية بولاية تيارت - زياني
                  عبد الحق - جامعة إبن خلدون -تيارت - الجزائر
                </li>
                <li>
                  Investigation of Using Modern Technologies (Google Meet
                  Application) for 4th Year Dissertation Students Supervision in
                  Faculty of Arts &amp; Education / Zwara - ابتسام صالح القروي -
                  University of Zawia-faculty of Education-Zwara - 0044
                </li>
                <li>
                  واقع التكنولوجيا المالية في ليبيا "التحديات والفرص" - دراسة
                  تطبيقية على أصحاب المصلحة للتكنولوجيا المالية - م. محمد أحمد
                  الأسود - خبراء فرنسا - تونس - Libya
                </li>
                <li>
                  جائحة كورونا وإلزامية التحول نحو التعليم الالكتروني في الجامعة
                  الجزائرية - نشادي عبد القادر - جامعة يحي فارس بالمدية -
                  الجزائر
                </li>
                <li>
                  دور اخصائي المعلومات في ارساء عصر المعرفة - أ. د \ لطفية علي
                  الكميشي - جامعة طرابلس - ليبيا
                </li>
                <li>
                  اسهامات التعليم الالكتروني في مجال إكتساب المعرفة الرقمية لدى
                  طلاب الجامعات العربية ( الجامعات العراقية انموذجاً ) - ا.م. د.
                  وائل جبار جودة - جامعة المثنى/كلية التربية للعلوم الانسانية -
                  العراق
                </li>
                <li>
                  الاقتصاد الرقمي في الدولة العراقية بين النجاحات والاخفاقات
                  والتحديات - د. طالب سلطان حمزة - جامعة بابل / كلية الفنون
                  الجميلة - العراق
                </li>
                <li>
                  مساهمة الحلول الرقمية في تعزيز درجة الشمول المالي -تجربة كينيا
                  أنموذجا- - بوزانة أيمن - جامعة باجي مختار /كلية العلوم
                  الاقتصادية وعلوم التسيير/ مخبر المالية الدولية ودراسة الحوكمة
                  والنهوض الاقتصادي - 0213
                </li>
                <li>
                  دور الاقتصاد الرقمي بأدارة العلاقات الاقتصادية الحديثة -
                  الدكتور ميثاق بيات الضيفي - جامعة تكريت/ كلية التمريض - العراق
                </li>
                <li>
                  التحول نحو نظام الإدارة الالكترونية في ليبيا ( الواقع والافاق)
                  ..... دراسة مقارنة . - د / عبدالناصر بشير عبدالله الصغير -
                  جامعة الزاويه / كلية الاقتصاد / العجيلات - ليبيا
                </li>
                <li>
                  دور التعليم الالكتروني في رفع كفاءة التحصيل العلمي لذوي صعوبات
                  التعلم - أسماء حسين بوبكر العقيلي - جامعة بنغازي- كلية التربية
                  - ليبيا
                </li>
                <li>
                  دور التعليم الالكتروني في تحقيق نظام الجود الشاملة مؤسسات
                  التعليم الجامعي التعليم الخاص انموذجا - د.حسين حسين زيدان -
                  وزارة التربية والتعليم - المديرية العامة لتربية ديالى - العراق
                </li>
                <li>
                  دور المنظمات العربية والاقليمية في تفعيل التحول الرقمي: ''
                  إسهامات الإتحاد الإفريقي في عملية التحول الرقمي في إفريقيا _
                  قراءة في مشروع استراتيجية التحول الرقمي لإفريقيا ''
                  2020/2030''_ - قاسم علي حاج - جامعة دكتور مولاي طاهر '' ولاية
                  سعيدة '' بالجزائر - الجزائر
                </li>
                <li>
                  دور التعليم المحاسبي الإلكتروني في رفع كفاءة التحصيل العلمي
                  لطلبة أقسام المحاسبة في الجامعات الليبية - محمد الطيب الشريف -
                  جامعة صبراتة - 00218
                </li>
                <li>
                  دور المواقع الإلكترونية للمؤسسات الوثائقية في إتاحة المحتوى
                  الرقمي في ظل أزمة كورونا - القري عبدالباسط - مخبر تكنولوجيا
                  المعلومات ودورها في التنمية الوطنية- جامعة قسنطينة02- الجزائر
                  - الجزائر
                </li>
                <li>
                  متطلبات نجاح التعليم الإلكتروني وإمكانية تطبيقه داخل المؤسسات
                  التعليمية و المراكز البحثية - يُـسر إمحمد الشبلي - جامعة
                  الزاوية - ليبيا
                </li>
                <li>
                  توظيف المعامل الافتراضية في تنمية مهارات إجراء االتجارب
                  المعملية لمواجهة مشكلة نقص الأجهزة و المعدات داخل المؤسسات
                  التعليمية - دليلة الطاهر الختروشي - جامعة الزاوية - ليبيا
                </li>
                <li>
                  الإدارة الالكترونية كخيار استراتيجي لتحسين الخدمات الصحية في
                  الجزائر - نصري منال - جامعة باجي مختار – عنابة - الجزائر -
                  الجزائر
                </li>
                <li>
                  التثمين الرقمي للتراث ودوره في تحسين التنمية السياحية|The
                  digital valuation of heritage and its role in improving the
                  tourism development - حرقاس زكرياء | Hargas zakaria - جامعة
                  البليدة02- لونيسي علي | University of Balida2-Lounici Ali -
                  الجزائر | Algeria
                </li>
                <li>
                  واقع التعليم الالكتروني في كلية تقنيات المعلومات بجامعة
                  الزاوية من وجهة نظر أعضاء هيئة التدريس والطلاب Reality of
                  E-learning in Information Technology College at Zawia
                  University as seen by Teachers and Students - أ. فتحي محمد
                  الحاج - المعهد العالي للعلوم والتقنية الأصابعة - ليبيا
                </li>
                <li>
                  مدى نجاح التجارب الدولية في التعليم الإلكتروني - محمد عامر
                  الجريدي - كلية الإقتصاد ، جامعة الزاوية - ليبيا
                </li>
                <li>
                  التعليم زمن الأزمات، فرص وقيود استخدام تكنولوجيا التعليم في
                  الوطن العربي - بودربالة عبد القادر Bouderbala Abdelkader - قسم
                  علوم الاعلام والاتصال، كلية العلوم الانسانية والاجتماعية،
                  جامعة قاصدي مرباح ورقلة - الجزائر
                </li>
                <li>
                  مستوى جودة التدريب الالكتروني في ضوء معايير ومؤشرات التعليم
                  الالكتروني في جامعة الزاوية من وجهة نظر المتدربين - نجوى عمار
                  عمر قجام - جامعة الزاوية/ كلية التربية - الزاوية / ليبيا
                </li>
                <li>
                  عنوان الورقة البحثية : دور الاقتصاد الرقمي في تعزيز كفاءة
                  القطاع المصرفي – دراسة تحليلية على المصارف التجارية في مدينة
                  الجميل – ليبيا. - نبيل محمد عمار عبدالجليل - جامعة الزاوية /
                  كلية الاقتصاد - العجيلات - ليبيا
                </li>
                <li>
                  إسهامات السياحة الالكترونية في معدلات السفر الدولي - دراسة
                  حالة: دولة فرنسا - ناصر عبد الكريم الغزواني - جامعة عمر
                  المختار - ليبيا
                </li>
                <li>
                  الإدارة الالكترونية في الجزائر،الواقع والمأمول، -رقمنة الحالة
                  المدنية أنموذجا- - حمودي عولة - جامعة الجـــــزائر2 - الجزائر
                </li>
                <li>
                  التحول الرقمي في الجامعات العربية ( الجامعات العراقية نموذجا(
                  - فاطمة نصر بن ناجي - كلية الآداب العجيلات/ جامعة الزاوية -
                  ليبيا
                </li>
                <li>
                  التحول الالكتروني في 2020 - سمير المختار السيد كريمة - كلية
                  التربية / جامعة الزاوية - 00218
                </li>
                <li>
                  مراجع التأصيل لرقمنة التعليم - نيرمين ماجد جميل البورنو -
                  جامعة الاسراء - فلسطين
                </li>
                <li>
                  Seman Search - A Semantic Agent for Searching Using Semantics
                  - suaad awad albadry - Benghazi University - Libya
                </li>
                <li>
                  دور التعليم الإلكتروني في مرحلة التعليم الأساسي وأهم معوقاته.
                  - لبنى عبد السلام محمد عبدالله. - جامعة الزاوية/كلية التربية.
                  - 00218
                </li>
                <li>
                  تصميم التعليم الإلكتروني وخريطة طريق المحتوى Designing
                  electronic transformation programs and a content roadmap - د
                  محمد امحمد البشتي - جامعة الزاوية - ليبيا
                </li>
                <li>
                  التعليم الرقمي بالجزائر وأثره على التحصيل المعرفي للطالب
                  الجامعي (طلبة شعبة التاريخ بجامعة الوادي أنموذجا) - رضوان شافو
                  - جامعة الوادي – الجزائر - الجزائر
                </li>
                <li>
                  نحو سياسة فعالة للقضاء على تزوير الشهادات الجامعية (مصر
                  نموذجا) - محمود أحمد عبد القادر - جامعة أسيوط - مصر
                </li>
                <li>
                  دور التحول الرقمي في تحسين التنمية السياحة - خالد ممدوح العزي
                  - الجامعة اللبنانية كلية الاعلام والتواصل - لبنان
                </li>
                <li>
                  دور التقنيات التعلمية الحديثة في التعليم عن بُعد في ظل الظروف
                  المتغيرة - .عبدالكريم عبدالرحيم السنوسي - جامعة اجدابيا -
                  ليبيا
                </li>
                <li>
                  الحكومة الإلكترونية كأداة تدبيرية لتحديث الإدارة الليبية -
                  خالد أحمد محمد إبزيم - جامعة سبها - ليبيا
                </li>
                <li>
                  التدريب الالكتروني لاطفال التوحد في ظل جائحة كورونا - قدام
                  نادية - جامعة الجزائر 2 ، جزائر العاصمة - الجزائر
                </li>
                <li>
                  دور التعليم الالكتروني في التخفيف من قلق الامتحان لدى تلاميذ
                  البكالوريا في ظل إنتشار وباء كوفيد-19 - سلطاني أسماء - جامعة
                  محمد بوضياف-المسيلة - الجزائر
                </li>
                <li>
                  Investigation of Using 'Google Docs' in Teaching Writing: an
                  Alternative Way for Teaching Writing Out-Of-Class - زمزم امحمد
                  مرعي - كلية التربية زويلة- جامعة سبها / ليبيا - Libya
                </li>
                <li>
                  أثر الاستثمار في التعليم الإلكتروني في تحسين نتائج التعلمThe
                  Impact of E-learning investment in improving learning outcomes
                  - وردة المبروك عبدالسلام زريمق - جامعة الزاوية كلية التربية
                  العجيلات - ليبيا
                </li>
                <li>
                  Remote Teaching for Higher Education: Opportunities and
                  Challenges - Salamah Embarek Saleh - جامعة صبراته - Libya
                </li>
                <li>
                  استراتيجية التعلم المقلوب و أهم التحديات التي تواجهها في الوطن
                  العربي - Hind Mehdaoui - المركز الجامعي بلحاج بوشعيب عين
                  تموشنت - الجزائر
                </li>
                <li>
                  أثر التحول الرقمي في مؤسسات التعليم العالي على صناعة التميز
                  الإداري –دراسة حالة جامعة محمد بوضياف بالمسيلة- - بلواضح أحمد
                  سيف الدين - جامعة مولاي الطاهر سعيدة، كلية العلوم الاقتصادية
                  والتجارية وعلوم التسيير، مخبر البحث: إدارة وتقييم أداء
                  المؤسسات "إتمام" - الجزائر
                </li>
                <li>
                  عوائق تطبيق و تنفيذ برنامج التعليم الالكتروني في كلية الاقتصاد
                  جامعة الزاوية - عبد المنعم المنتصر علي القمي - كلية الاقتصاد
                  جامعة الزاوية - ليبيا
                </li>
                <li>
                  دور التعليم الالكتروني في استمرار التعليم الجامعي خلال جائحة
                  كورونا 2020 *جامعة حائل السعودية نموذجا* - د.زهية يسعد - جامعة
                  قاصدي مرباح الجزائر وجامعة حائل بالمملكة العربية السعودية -
                  المملكة العربية السعودية
                </li>
                <li>
                  استراتيجيات تطبيق تكنولوجيا النانو في التنمية الاقتصادية -
                  المشروع الجزائري بين الواقع والتحديات- - زهرة شوشان - جامعة
                  أكلي محند أولحاج. البويرة. الجزائر - الجزائر
                </li>
                <li>
                  دور التدريب الإلكتروني في تحسين كفاءة الموارد البشرية في
                  منظمات الأعمال - الدكتور: مراد بن حرزالله - المركز الجامعي علي
                  كافي تندوف - الجزائر - الجزائر
                </li>
                <li>
                  تطبيق التقنيات الحديثة في البحوث العلمية داخل المؤسسات
                  التعليمية - الاستاذة:حنان الهادي بشير فرعون - كلية الاداب
                  العجيلات جامعة الزاوية - ليبيا
                </li>
                <li>
                  Experiences of Blended Learning in Libyan Higher Education - د
                  محمد الشيباني محمد أبوشعفة Mohamed Abushafa - University of
                  Zawia جامعة الزاوية - UK
                </li>
                <li>
                  دور الجانب السياسي للتحول الرقمي للتنمية المستدامة " تونس
                  نموذج" - سميرة بنكريم - جامعة تونس المنار كلية الحقوق والعلوم
                  السياسية بتونس - تونس
                </li>
                <li>
                  نحو خلق بيئة قانونية للحكومة الإلكترونية في ليبيا تدعم تطبيق
                  الاقتصاد الرقمي - فرج حسن محمد الاطرش - جامعة الجفرة - كلية
                  القانون - ليبيا
                </li>
                <li>
                  العلوم الشرعية وتقنيات التعليم - حسن مولود حسن الشيباني - كلية
                  التربية أبو عيسى - جامعة الزاوية &amp; مدرّب في مركز التعليم
                  الإلكتروني - ليبيا
                </li>
                <li>
                  أثر تطبيق نظم إدارة التعلم الالكتروني بالمعهد العالي للعلوم
                  والتقنية بالعزيزية- ليبيا - فتحي محمد وادي ضو - المعهد العالي
                  للعلوم و التقنية العزيزية - ليبيا
                </li>
                <li>
                  درجة امتلاك معلمي المدارس الحكومية بالمحافظات الجنوبية لفلسطين
                  لكفايات التعليم الإلكتروني وسبل تطويرها - محمد حسن أبورحمة -
                  وزارة التربية والتعليم العالي - فلسطين
                </li>
                <li>
                  قدرة المدن الذكية على مواجهة أزمة وباء الفيروس التاجي 2019
                  لتنمية مستدامة - دراسة حالة سنغافورة – - دويدي خديجة هاجر -
                  جامعة امحمد بوقرة - بومرداس - الجزائر
                </li>
                <li>
                  مدى كفاءة الكوادرالاكاديمية بالجامعات الليبية في التعليم
                  الإلكتروني (الكادرالأكاديمي بكلية التربية المرج –جامعة بنغازي
                  والتربيةالعجيلات -جامعةالزاويةانموذجآ ) The Extent of Libyan
                  Universities Staff Members’ Competence of E-Learning (Almarj
                  College of Education in Bengahzi University / Agelat College
                  of Education in Zawia University) - حامد المبروك صالح احمد -
                  جامعة بنغازي- كلية التربية - ليبيا
                </li>
                <li>
                  مقترح لاستخدام الامتحان الإلكتروني بالتعليم العالي من وجهة نظر
                  المختصين A proposal to use the electronic examination in
                  higher education from the viewpoint of specialists - د محمد
                  امحمد البشتي - جامعة الزاوية / كلية تقنية المعلومات - ليبيا
                </li>
                <li>
                  تكنولوجيا الإعلام والاتصال كآلية لتحسين الجذب السياحي "حالة
                  الجزائر" Information and communication technology as a
                  mechanism for improving tourist attractions "the case of
                  Algeria" - بن ناصر سيد أحمد - جامعة عبد الحميد بن باديس
                  مستغانم - الجزائر
                </li>
                <li>
                  التقنيات الحديثة في التعليم: الذكاء الاصطناعي والبيانات الضخمة
                  واستشراف المستقبل (دراسة وصفية) - Khadija Mansour Abuzagia -
                  Elmergib - Libya
                </li>
                <li>
                  التعليم الالكتروني ودوره في تنمية بعض مهارات ذوي الاحتياجات
                  الخاصة - سعاد العبيدي - جامعة بنغازي - ليبيا
                </li>
                <li>
                  International economic applications of digital transformation
                  in the era of knowledge - Eman Adel Eid Hassan - university in
                  Egypt - Egypt
                </li>
                <li>
                  دور برامج الهاتف الذكي و الايباد في تحسين التفاعل الاجتماعي من
                  خلال تنمية مهارات التواصل اللفظي لدى الطفل التوحدي. - طالبة
                  الدكتوراه حفصة رزيق - جامعة محمد بوضياف المسيلة.الجزائر - 0213
                </li>
                <li>
                  اهميةالتحول الرقمي في تحسين التنمية البشرية المستدامة -
                  الدكتور حسيب محمد فقيه - الجامعة اللبنانية -معهد العلوم
                  الاجتماعية - لبنان
                </li>
                <li>
                  الاتجاه نحو التربية الرقمية ( الواقع – الطموح ) من وجهة نظر
                  أعضاء الهيئة التدريسية للمدارس الثانوية - ربيعة مانع زيدان
                  الحمداني - وزارة التربية العراقية / مديرية تربية صلاح الدين -
                  العراق
                </li>
                <li>
                  الإدارة الالكترونية للخدمات الصحية وتحديات التحول الرقمي في
                  الدول العربية - دراسة حالة مملكة البحرين - - صيفي حسنية -
                  جامعة قاصدي مرباح ورقلة، الجزائر - 0213
                </li>
                <li>
                  اثر التحول الرقمي على مقاربات التعليم: التعليم الاليكتروني -
                  دراسة حالة الديوان الوطني لتعليم وتكوين عن بعد الجزائر- -
                  زغدود مرج - جامعة الجزائر3/ كلية العلوم الاقتصادية والعلوم
                  التجارية وعلوم التسيير/ قسم علوم التسيير/ مخبر إدارة التغيير
                  في المؤسسة الجزائرية. - الجزائر
                </li>
                <li>
                  التحديات التي تواجه المعلمين في استخدام وسائل التعليم
                  الإلكتروني لتعليم ذوي الإحتياجات الخاصة - سعاد أبوبكر
                  عبدالسلام غيث - المعهد العالي للعلوم و التقنية وادي الآجال -
                  ليبيا 00218
                </li>
                <li>
                  دور الجامعات الليبية في توظيف التعليم الإلكتروني (التعلّم عن
                  بعد )من وجهة نظر أعضاء هيأة التدريس ( جامعة أجدابيا انموذجا) -
                  عمر عبد الحميد مفتاح المغربي - جامعة أجدابيا - 00218
                </li>
                <li>
                  استخدام الأساليب التقنية الحديثة في التعليم داخل المؤسسات
                  التعليمية. - Abeer Alawamy - كلية إدارة الاعمال بالجامعة
                  الليبية الدولية للعلوم الطبية - ليبيا
                </li>
              </ol>
            </ol>
          </div>
        </div>
        <div class="modal-footer">
          <a href="#!" class="modal-close waves-effect waves-green btn-flat">
            {Close[lang]}
          </a>
        </div>
      </div>

      <footer class="section teal darken-2 white-text center">
        <p class="flow-text">
          &copy; {footer[lang]}
          {"  "}
          {new Date().getFullYear()}
        </p>
      </footer>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  lang: state.research.lang,
});

export default connect(mapStateToProps, { ChangeLang })(HomePage);
